import React from "react";
import theme from "theme";
import { Theme, Box, Strong, Em, Text, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"datacenters3pct"} />
		<Helmet>
			<title>
				SPhotonix — Revolutionizing Data Storage & Photonics
			</title>
			<meta name={"description"} content={"Discover SPhotonix’s cutting-edge data storage solutions. We specialize in 5D optical storage technology, pushing the boundaries of durability, scalability, and future-proof innovation for enterprise storage systems. Partner with us to bring next-generation data storage to market."} />
			<meta property={"og:title"} content={"SPhotonix — Revolutionizing Data Storage & Photonics"} />
			<meta property={"og:description"} content={"SPhotonix pioneers the future of data storage with breakthrough 5D optical technology. Our innovative solutions offer durable, scalable, and ultra-secure storage for enterprises looking to safeguard their data for decades. Explore how we are revolutionizing the industry."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/sphotonix-new-logo-white.svg?v=2024-09-17T17:56:51.435Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/SPhotonix-fav32w.png?v=2024-09-23T14:28:13.820Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/SPhotonix-fav152w.png?v=2024-09-23T14:28:06.690Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/SPhotonix-fav152w.png?v=2024-09-23T14:28:06.690Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/SPhotonix-fav152w.png?v=2024-09-23T14:28:06.690Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/SPhotonix-fav152w.png?v=2024-09-23T14:28:06.690Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/SPhotonix-fav270w.png?v=2024-09-23T14:27:58.132Z"} />
			<meta name={"msapplication-TileColor"} content={"#ffffff"} />
		</Helmet>
		<Components.Header position="fixed" right="0px" left="0px">
			<Override slot="linkBox7" color="white" />
			<Override slot="text17" color="--accentOrange" />
		</Components.Header>
		<Box height="88px" sm-height="52px" />
		<Box min-width="100px" min-height="100px" md-padding="120px 16px 120px 16px">
			<Box
				min-width="100px"
				min-height="100px"
				align-items="center"
				display="flex"
				flex-direction="column"
				justify-content="center"
				flex-wrap="no-wrap"
				grid-row-gap="36px"
				padding="50px 16px 100px 16px"
				margin="0px auto 0px auto"
				max-width="1348px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					flex-wrap="no-wrap"
					align-items="flex-start"
					justify-content="center"
					grid-row-gap="16px"
				>
					<Text
						margin="0 0 0 0"
						word-wrap="break-word"
						text-fill-color="transparent"
						style={{
							"-webkit-text-fill-color": "transparent"
						}}
						background=" #080808"
						background-clip="text"
						font="--headline3"
					>
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Data Centers contributing to 3% of global{" "}
							<br />
							carbon footprint, and growing
						</Strong>
						<br />
						{"\n\n"}
						<br />
						<Em
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								Date: 18th March 2025
							</Strong>
							{"\n\n\n\n\n\n\n\n"}
						</Em>
					</Text>
					<Text
						margin="0 0 0 0"
						word-wrap="break-word"
						text-fill-color="transparent"
						style={{
							"-webkit-text-fill-color": "transparent"
						}}
						background=" #080808"
						background-clip="text"
						font="--bodyText17SemiBold"
					/>
					<Text margin="0px 0px 0px 0px" text-align="justify" font="--bodyText21Regular">
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						/>
						{"\n"}
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Global Data Center industry has a higher carbon footprint than aviation industry
						</Strong>
						<br />
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							90% of digital data has been produced in the last two years
						</Strong>
						<br />
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Over 394ZB of data is predicted to be generated in 2028
						</Strong>
						<br />
						<br />
						Recent research shows that{" "}
						<Link
							href="https://explodingtopics.com/blog/data-generated-per-day"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							90% of all digital data in existence today, has been produced in the last two years
						</Link>
						, largely attributed to the explosive increase in AI and IOT use. The total amount of data created, captured, copied, and consumed globally reached{" "}
						<Link
							href="https://www.statista.com/statistics/871513/worldwide-data-created/"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							149 zettabytes in 2024 and over the next five years up to 2028, global annual data creation is projected to increase to more than 394 zettabytes
						</Link>
						. While the use of data has been a game-changer for organisations and individuals all over the world, the rapid take-up of AI and the internet has exacerbated the level of information available, and with it, a greater demand for data storage.{" "}
						<br />
						<br />
						Research from McKinsey and Company shows that if global{" "}
						<Link
							href="https://www.mckinsey.com/industries/technology-media-and-telecommunications/our-insights/ai-power-expanding-data-center-capacity-to-meet-growing-demand"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							demand for disk-based data center capacity continues to grow at the pace it is, we could see an annual rise of between 19 to 22 percent each year to 2030
						</Link>
						. By 2030, it is thought that 13% of the world’s electricity consumption will be attributed to data centers, largely due to growing AI demand. Having more information and data at our fingertips comes with many advantages, however, traditional methods of storage for this data come with environmental concerns around significant energy consumption, water usage, and carbon emissions. Morgan Stanley research shows that a boom in data centers is expected to produce around 2.5 billion metric tons of carbon dioxide-equivalent emissions globally through the end of the decade, and accelerate investments in decarbonization efforts.
						<br />
						<br />
						Data centers worldwide consume approximately{" "}
						<Link
							href="https://www.iea.org/reports/electricity-2024/executive-summary"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							460 terawatt-hours (TWh) of electricity, a figure projected to more than double to 1,000 TWh by 2026
						</Link>
						, the equivalent of Japan's entire annual energy consumption. If we continue to follow this trajectory, the data storage center industry will emit 2.5bn tons of CO2 by 2030. The carbon footprint of data centers is equally concerning. They are estimated to produce around 3% of global carbon footprint when it comes to gas emissions, the same amount as the aviation industry.{" "}
						<br />
						<br />
						There are similar concerns around the environmental challenges of HDD / Tape storage used for long-term cold data storage and archiving (accounting for 60% of all data stored today). These need to be kept at low temperatures using extensive amounts of energy to preserve the data. The physical tapes also require regular monitoring for degradation and must be copied every 7-10 years, costing vast energy and CO2 expense. Not to mention the higher possibility of damage to the data.
						<br />
						<br />
						E-waste is quickly becoming one of the fastest-growing waste streams, with an estimated 53.6 million metric tons generated globally projected to reach 74 million metric tons by 2030. Data storage devices, including HDDs, optical disks, and tape drives are difficult to recycle due to their complex composition and use of synthetic materials, further contributing to the growing e-waste crisis. Longer-lasting storage solutions, including technologies such as 5D optical storage, reduce the need for frequent replacements, dramatically lowering e-waste and energy consumption. To store 2ZB of data would require over 60 Million LTO9 tape cartridges weighing 225g, which generate 13,500 metric tons of waste when they reach end of life.
						<br />
						<br />
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Commenting on the impact of this, Peter Kazansky, Chief Science Officer, at{" "}
							<Link
								href="https://sphotonix.com/"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								SPhotonix
							</Link>
							{" "}said
						</Strong>
						: “In an increasingly digital world, the environmental impact of data storage is quickly becoming a pressing concern with respective Governments and Regulatory bodies stepping in to enforce sustainability standards. The European Energy Efficiency Directive, for example, includes provisions specifically targeting this, and countries like Germany will require new data centers to achieve a power usage efficiency of 1.2 or less from 2026.”
						<br />
						<br />
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Revolutionizing the future of data storage
						</Strong>
						<br />
						<br />
						But there is a better way – Scientists at{" "}
						<Link
							href="https://sphotonix.com/"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							SPhotonix
						</Link>
						{" "}have been developing a solution to this in the form of a 5D Optical Storage which will shape the future of data storage. The 5D Memory Crystal utilises FemtoEtch™, nano etching technology and can store up to 360TB data on a 5-inch glass platter. Delivering virtually unlimited data storage longevity with low environmental impact means SPhotonix technology meets future storage demands without compromising the planet.{" "}
						<br />
						<br />
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Kazansky, continues, said
						</Strong>
						: “Groundbreaking advancements and discoveries in technology have transformed the way we access and interpret data. The 5D memory crystal offers limitless potential, and SPhotonix is eager to explore various industries for innovative applications of its technology. With ever-increasing compliance requirements and the need for some industries to keep data for over 30 years, organisations are having to look at the most eco-friendly way of doing this. Reliable data management plays a vital role in addressing energy challenges, enabling efficient resource allocation and long-term planning.
						<br />
						<br />
						“Enhanced regulations along with technological innovations and community engagement, are crucial steps toward reducing the environmental impact of global data storage. Through concerted efforts encompassing technological advancements, regulatory measures, and sustainable practices, the data storage industry can move toward a more environmentally friendly future.”
						<br />
						SPhotonix was founded upon over three decades of Prof. Kazansky’s research at the{" "}
						<Link
							href="https://www.southampton.ac.uk/news/2024/09/human-genome-stored-on-everlasting-memory-crystal-.page"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							University of Southampton’s Optoelectronics Research Center{" "}
						</Link>
						that recently stored the human genome on a 5D memory crystal that can last billions of years. The revolutionary technology developed for that purpose is now being applied to create these groundbreaking optical elements.{"\n\n\n\n\n\n"}
					</Text>
				</Box>
			</Box>
		</Box>
		<Box
			width="100%"
			padding-top="100px"
			padding-bottom="24px"
			padding-left="190px"
			padding-right="190px"
			flex-direction="column"
			justify-content="flex-start"
			align-items="flex-start"
			grid-gap="140px"
			display="flex"
			sm-display="flex"
			sm-padding="24px 16px 24px 16px"
			sm-height="min-content"
			sm-grid-row-gap="64px"
			quarkly-title="Footer"
			grid-row-gap="0px"
			grid-column-gap="0px"
			padding="0px 190px 24px 190px"
			md-padding="0px 16px 24px 16px"
		>
			<Box
				align-self="stretch"
				height="51px"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				grid-gap="24px"
				display="flex"
				sm-display="flex"
				sm-grid-row-gap="32px"
				sm-height="auto"
			>
				<Box align-self="stretch" height="1px" background="#B1B1B1" width="100%" />
				<Components.Footer />
			</Box>
		</Box>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"66b79a6ce778710018d27b83"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}\n\n\nbody  {\nzoom: 0.85;\n}\n"}
			</style>
			<script async={true} src={"https://www.googletagmanager.com/gtag/js?id=G-GFK1N3P5RL"} place={"endOfBody"} rawKey={"670146df6aba74b20ebdd83f"} />
			<script place={"endOfBody"} rawKey={"670147228b237590430d8fce"}>
				{"  window.dataLayer = window.dataLayer || [];\n  function gtag(){dataLayer.push(arguments);}\n  gtag('js', new Date());\n\n  gtag('config', 'G-GFK1N3P5RL');"}
			</script>
			<link
				href={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/sphotonix-favicon.ico?v=2025-03-25T15:31:48.817Z"}
				type={"image/x-icon"}
				rel={"icon"}
				place={"endOfHead"}
				rawKey={"67e2cb378695622ce9816272"}
			/>
		</RawHtml>
	</Theme>;
});